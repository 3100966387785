<template>
  <el-row class="warp">
    <el-col :span="24" class="warp-breadcrum">

      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }"><b>首页</b></el-breadcrumb-item>
        <el-breadcrumb-item>内容管理</el-breadcrumb-item>
        <el-breadcrumb-item>一日游</el-breadcrumb-item>
      </el-breadcrumb>

    </el-col>

    <el-col :span="24" class="warp-main">
      <el-col :span="24" class="toolbar" style="padding-bottom: 0px;">
        <el-form :inline="true" :model="filters">
          <el-form-item>
            <el-input v-model="filters.name" placeholder="请输入名称" clearable></el-input>
          </el-form-item>

          <el-form-item>
            <el-select v-model="filters.travelName" placeholder="请选择旅行社" class="el-select-big" clearable>
              <el-option v-for="item in typeArray" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" v-on:click="reqGetTourListPage" icon="search">查询</el-button>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" v-on:click="goReset" icon="search">重置</el-button>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" icon="plus"  v-if="permissionButton.add" @click="$router.push({ path: '/tour/add' })">新增</el-button>
          </el-form-item>
        </el-form>
      </el-col>
      <el-table :data="tour" highlight-current-row v-loading="listLoading" @selection-change="selsChange" style="width: 100%;" border>
        <el-table-column type="selection" width="50" align="center"></el-table-column>
        <el-table-column type="index" label="序号" width="60" align="center"></el-table-column>
        <el-table-column prop="name" label="名称" min-width="300"></el-table-column>
        <el-table-column prop="tourGroupName" label="旅行社名称" min-width="200"></el-table-column>
        <el-table-column prop="indexImageUrl" label="列表缩略图片" width="130" align="center">
          <template slot-scope="scope">
            <img v-if="scope.row.indexImageUrl"  style="width: 89px; height: 50px" :src="downloadFileURL + scope.row.indexImageUrl + '/'"/>
          </template>
        </el-table-column>
          <el-table-column prop="isTop" label="置顶" width="80" align="center" sortable>
              <template slot-scope="scope">
                  <span v-bind:class="{ temporary: scope.row.isTop == '是' }">{{scope.row.isTop}}</span>
              </template>
          </el-table-column>
        <el-table-column prop="status" label="状态" width="80" align="center" sortable>
          <template slot-scope="scope">
            <span v-bind:class="{ temporary: scope.row.status == 1 }">{{scope.row.status == 0 ? "已发布" : "草稿"}}</span>
          </template>
        </el-table-column>
 
        <el-table-column prop="publisher" label="发布人" width="100" align="center"></el-table-column>
        <el-table-column prop="updateTime" label="修改时间" width="180" align="center" sortable>
          <template slot-scope="scope">
            <span>{{scope.row.updateTime ? scope.row.updateTime : "暂无更改"}}</span>
          </template>
        </el-table-column>
        <el-table-column  prop="createTime" label="创建时间" width="180" align="center" sortable></el-table-column>

        <el-table-column label="操作" width="220" align="center" fixed="right">
          <template slot-scope="scope">
            <el-button size="small" v-if="permissionButton.view"
              @click=" $router.push({path: '/tour/view',query: { id: scope.row.id },})">查看</el-button>
            <el-button size="small" type="primary" v-if="permissionButton.edit" @click="$router.push({path: '/tour/edit',query: { id: scope.row.id },})">编辑</el-button>
            <el-button type="danger" @click="delTour(scope.$index, scope.row)" size="small" v-if="permissionButton.del" >删除</el-button>
          </template>
        </el-table-column>
      </el-table>

      <el-col :span="24" class="table-footer">
        <el-button type="danger" size="small" @click="batchDeleteTour" :disabled="this.sels.length === 0" v-if="permissionButton.batchDel" >批量删除</el-button>
        <el-pagination  @size-change="handleSizeChange"  @current-change="handleCurrentChange"
          :page-sizes="[10, 15, 20, 25, 50]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper"
          :total="total" style="float:right;">
        </el-pagination>
      </el-col>
    </el-col>
  </el-row>
</template>
<script>
    import { downloadFileURL,} from "../../../api/system-api";
    import {reqGetTourListPage, reqDeleteTour, reqBatchDeleteTour,reqGetTourGroupList} from "../../../api/tour-api";
    import permission from "../../../common/permission";
    export default {
        data() {
            return {
                filters: {
                    name: "",
                    travelName:'',
                },
                downloadFileURL: downloadFileURL,
                columnsTypeArray: [],
                tour: [],
                total: 0,
                pageNum: 1,
                pageSize: 15,
                listLoading: false,
                sels: [],
                typeArray: [],
                permissionButton: new permission.PermissionButton(),
            };
        },
        methods: {
            handleCurrentChange(val) {
                this.pageNum = val;
                this.reqGetTourListPage();
            },
            handleSizeChange(val) {
                console.log(`每页 ${val} 条`);
                this.pageSize = val;
                this.reqGetTourListPage();
            },
            reqGetTourListPage() {
                let para = {
                    pageNum: this.pageNum,
                    pageSize: this.pageSize,
                    name: this.filters.name,
                    travelName:this.filters.travelName
                };
                this.listLoading = true;
                reqGetTourListPage(para).then((res) => {
                    this.total = res.data.data.total;
                    this.tour = res.data.data.records;
                    this.listLoading = false;
                });
            },
            selsChange: function (sels) {
                this.sels = sels;
            },
            goReset() {
                this.filters.name = "";
                this.filters.travelName='';
                this.pageNum = 1;
                this.reqGetTourListPage();
            },

            // 删除
            delTour: function (index, row) {
                this.$confirm("确认删除该记录吗?", "提示", {type: "warning"})
                    .then(() => {
                        this.listLoading = true;
                        let para = {id: row.id};
                        reqDeleteTour(para).then((res) => {
                            if (res.data.code === 1) {
                                this.listLoading = false;
                                this.$message({
                                    message: "删除成功",
                                    type: "success",
                                });
                                this.reqGetTourListPage();
                            } else {
                                this.listLoading = false;
                                this.$message({
                                    message: "删除失败",
                                    type: "error",
                                });
                                this.reqGetTourListPage();
                            }
                        });
                    })
                    .catch(() => {
                    });
            },
            // 批量删除
            batchDeleteTour: function () {
                var ids = this.sels.map((item) => item.id).toString();
                this.$confirm("确认删除选中记录吗？", "提示", {
                    type: "warning",
                })
                    .then(() => {
                        this.listLoading = true;
                        let para = {ids: ids};
                        reqBatchDeleteTour(para).then((res) => {
                            if (res.data.code === 1) {
                                this.listLoading = false;
                                this.$message({
                                    message: "删除成功",
                                    type: "success",
                                });
                                this.reqGetTourListPage();
                            } else {
                                this.listLoading = false;
                                this.$message({
                                    message: "删除失败",
                                    type: "error",
                                });
                                this.reqGetTourListPage();
                            }
                        });
                    })
                    .catch(() => {
                    });
            },
            getTourGroupListPage() {
                var that = this;
                reqGetTourGroupList().then((res) => {
                    if (res.data.code === 1) {
                        that.typeArray = res.data.data
                    } else {
                        that.$message({
                            message: "查询失败",
                            type: "error",
                        });
                    }
                })
            },
        },
        mounted() {
            this.reqGetTourListPage();
            this.getTourGroupListPage();
            var permissionId = sessionStorage.getItem("permissionId");
            permission.getPermissionButton(permissionId, this.permissionButton);
        },
    };
</script>
